import { isTesting, macroCondition } from '@embroider/macros';

export const EINVOICING_STATUS_COLORS = {
  approved: 'green',
  not_delivered: 'red',
  pending: 'orange',
  declined: 'red',
  submitted: 'orange',
  submission_failed: 'red',
};

export const EINVOICING_UNIT_CODES = [
  'unit',
  'hour',
  'day',
  'month',
  'gram',
  'kilogram',
  'liter',
  'meter',
  'square_meter',
  'cubic_meter',
  'ampere',
  'number_of_articles',
  'centimeter',
  'cubic_meter_per_hour',
  'gigajoule',
  'gigawatt',
  'gigawatt_hour',
  'half_year',
  'joule',
  'kilojoule',
  'kilovar',
  'kilowatt',
  'kilowatt_hour',
  'megajoule',
  'megawatt',
  'megawatt_hour',
  'milligram',
  'milliliter',
  'millimeter',
  'minute',
  'pair',
  'quarter',
  'second',
  'set',
  'ton',
  'two_week',
  'watt_hour',
  'week',
  'year',
];

export const INVOICING_STATUS_COLORS = {
  paid: 'green',
  unpaid: 'purple',
  canceled: 'red',
  draft: 'gray',
};

export const INVOICED_STATUS = {
  FULLY_INVOICED: 'fully_invoiced',
  PARTIALLY_INVOICED: 'partially_invoiced',
  NO_INVOICES: 'no_invoices',
  CURRENCY_MISMATCH: 'currency_mismatch',
};

export const EINVOICING_STATUS = {
  APPROVED: 'approved',
  NOT_DELIVERED: 'not_delivered',
  PENDING: 'pending',
  REJECTED: 'declined',
  SUBMITTED: 'submitted',
  SUBMISSION_FAILED: 'submission_failed',
};

export const STATUS = {
  DRAFT: 'draft',
  PAID: 'paid',
  UNPAID: 'unpaid',
  CANCELED: 'canceled',
  COMPLETED: 'paid,canceled',
};

export const DEFAULT_VAT_RATE_PER_COUNTRY = {
  FR: '0.2',
  DE: '0.19',
  ES: '0.21',
  IT: '0.22',
};

export const DEFAULT_LOCALE_PER_COUNTRY = {
  FR: 'fr',
  IT: 'it',
  DE: 'de',
  ES: 'es',
};

export const TERM_AND_CONDITIONS_MAX_LENGTH = 525;
export const TERM_AND_CONDITIONS_MAX_LENGTH_FOR_IT = 200;
export const LOCAL_STORAGE_WARNING_DISMISSED_KEY =
  'receivable-invoice-manual-numbering-warning-dismissed';

export const NUMBERING_MODE = {
  MANUAL: 'manual',
  AUTOMATIC: 'automatic',
};

export const DISCOUNT_TYPES = [
  { value: 'percentage', label: '%' },
  { value: 'absolute', label: 'EUR' },
];

export const SENTRY_IGNORE_HTTP_STATUSES = [404, 409, 422];
export const ERROR_TOAST_IGNORE_HTTP_STATUSES = [409, 422];

/**
 * Timeouts, delays
 */
export const MATCHED_TRANSACTIONS_DELAY = macroCondition(isTesting()) ? 0 : 500;
export const WEBSOCKET_TIMEOUT_MS = macroCondition(isTesting()) ? 600 : 6000;
export const ATMA_WEBSOCKET_TIMEOUT_MS = macroCondition(isTesting()) ? 600 : 30000;

export const EXPORT_FORMATS = {
  PDF: 'pdf',
  FATTURAPA: 'fatturapa',
};

export const EXPORT_ERROR_TYPES = {
  LIMIT_REACHED: 'limit_reached',
  NO_INVOICES: 'no_invoices_to_export',
  GENERIC_ERROR: 'generic_error',
};

export const SDD_PAYMENT_METHOD = 'MP19';
export const SDD_PAYMENT_CONDITION = 'TP02';

export const DEFAULT_PAYMENT_METHOD = 'MP05';
export const DEFAULT_PAYMENT_CONDITION = 'TP02';

export const FULLPAGE_EMPTY_STATE_FF = {
  OLD_DESIGN: 'A',
  NEW_DESIGN_OLD_COPIES: 'B',
  NEW_DESIGN_NEW_COPIES: 'C',
  OFF: 'D',
};

export const VAT_RATE_PRECISION = 0.01;

export const ONBOARDING_STATUS = {
  SKIPPED: 'skipped',
  NOT_ELIGIBLE: 'not_eligible',
  NOT_STARTED: 'not_started',
  STARTED: 'started',
  COMPLETED: 'completed',
};

export const ONBOARDING_STEP_STATUS = {
  TO_DO: 'to_do',
  PREFILLED: 'prefilled',
  CONFIRMED: 'confirmed',
};
