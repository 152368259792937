import { SUPPLIER_STATUSES } from 'qonto/constants/supplier';

export const getDummyData = intl => {
  return {
    status: SUPPLIER_STATUSES.UNARCHIVED,
    items: [
      // 1st Row
      {
        name: intl.t('supplier-list.section.empty-state.a3.dummy-data.name-1'),
        email: intl.t('supplier-list.section.empty-state.a3.dummy-data.email-address-1'),
        outstandingBalance: {
          value: '366.00',
          currency: 'EUR',
        },
      },
      // 2nd Row
      {
        name: intl.t('supplier-list.section.empty-state.a3.dummy-data.name-2'),
        email: intl.t('supplier-list.section.empty-state.a3.dummy-data.email-address-2'),
        outstandingBalance: {
          value: '52.20',
          currency: 'EUR',
        },
      },
      // 3rd Row
      {
        name: intl.t('supplier-list.section.empty-state.a3.dummy-data.name-3'),
        email: intl.t('supplier-list.section.empty-state.a3.dummy-data.email-address-3'),
        outstandingBalance: {
          value: '1048.10',
          currency: 'EUR',
        },
      },
    ],
  };
};
