export const ENABLED_LEGAL_COUNTRIES = ['FR'];

export const PROVIDERS_MAP = {
  DE: 'Signal Iduna',
  FR: 'Stello',
};

export const OFFER_ROUTE = 'insurance-hub.offer';

export const INSURANCE_CONTRACT_STATUS = {
  ACTION_REQUIRED: 'action_required',
  ACTIVE: 'active',
  EXPIRED: 'expired',
  PENDING_OTHERS: 'pending_others',
  PENDING_PAYMENT: 'pending_payment',
};

export const INSURANCE_CONTRACT_INTERNAL_STATUS = {
  ACTION_REQUIRED: 'action_required',
  ACTIVE: 'active',
  SCHEDULED: 'scheduled',
  EXPIRED: 'expired',
};

export const INSURANCE_CONTRACT_INTERNAL_STATUS_ORDER = [
  INSURANCE_CONTRACT_INTERNAL_STATUS.ACTION_REQUIRED,
  INSURANCE_CONTRACT_INTERNAL_STATUS.ACTIVE,
  INSURANCE_CONTRACT_INTERNAL_STATUS.SCHEDULED,
  INSURANCE_CONTRACT_INTERNAL_STATUS.EXPIRED,
];

export const INSURANCE_CONTRACT_INTERNAL_STATUS_MAPPING = {
  [INSURANCE_CONTRACT_STATUS.ACTION_REQUIRED]: INSURANCE_CONTRACT_INTERNAL_STATUS.ACTION_REQUIRED,
  [INSURANCE_CONTRACT_STATUS.PENDING_PAYMENT]: INSURANCE_CONTRACT_INTERNAL_STATUS.ACTION_REQUIRED,
  [INSURANCE_CONTRACT_STATUS.ACTIVE]: INSURANCE_CONTRACT_INTERNAL_STATUS.ACTIVE,
  [INSURANCE_CONTRACT_STATUS.PENDING_OTHERS]: INSURANCE_CONTRACT_INTERNAL_STATUS.SCHEDULED,
  [INSURANCE_CONTRACT_STATUS.EXPIRED]: INSURANCE_CONTRACT_INTERNAL_STATUS.EXPIRED,
};

export const INSURANCE_CONTRACT_FREQUENCY = {
  MONTHLY: 'month',
  QUARTERLY: 'quarter',
  YEARLY: 'annual',
};
